.control-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.control-button {
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 0 10px;
  width: 40px; /* Adjusted width */
  height: 40px; /* Adjusted height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease;
  padding: 0; /* Remove padding */
}

.control-button i {
  color: #b6aca8; /* Adjust the color to match the play button in the image */
  font-size: 16px; /* Adjusted font size */
}

.control-button:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
