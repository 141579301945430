@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.session-history-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%; /* Adjust as needed */
  background-color: rgba(240, 240, 240, 0.95);
  z-index: 1000;
  border-radius: 20px 20px 0 0;
  overflow-y: auto;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-out forwards;
}

.session-history-drawer.exit {
  animation: slideDown 0.5s ease-out forwards;
}

.session-history-content {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: black;
  background: none;
  border: none;
  cursor: pointer;
}

.session-log-item {
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.total-focus-time {
  margin-top: 20px;
  font-weight: bold;
}
