body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scroll bars */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
  transition: background-image 0.3s ease-in-out;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.app {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.history-button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.freeflow-button {
  position: relative; /* Changed from absolute */
  z-index: 10;
  margin-bottom: 10px; /* Add some space above the navbar */
}

.session-history-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%; 
}

.session-history {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  max-height: 50vh;
  overflow-y: auto; /* Allow vertical scrolling if needed */
}

.session-history h2 {
  margin-top: 0;
}

.session-history ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.session-history li {
  margin: 5px 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1); /* Lightly transparent white */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: left; /* Ensure text is left-aligned */
}

.session-log-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.session-date,
.session-time,
.session-duration,
.session-text {
  margin: 2px 0;
}

.session-date::before,
.session-time::before,
.session-duration::before,
.session-text::before {
  font-weight: bold;
  content: attr(data-label);
}

.music-player.fade-in {
  opacity: 1;
  animation: fadeIn 1s ease-out forwards;
}

.music-player.fade-out {
  animation: fadeOut 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.control-button i {
  font-size: 36px;
}

.volume-bar-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 10; /* Ensure it appears above other elements */
}

.volume-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.volume-slider {
  width: 100px;
}

.top-bar {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.bottom-bar {
  margin-top: auto;
  text-align: center;
  z-index: 10; /* Ensure this is above other elements */
  cursor: pointer;
}

.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgba(200, 200, 200, 0.7); /* Light grey with 70% opacity */
  backdrop-filter: blur(5px); /* Adds a blur effect to the background */
  z-index: 5; /* Ensure it's below the freeflow button */
}

.photo-attribution {
  position: fixed;
  bottom: 70px; /* Positioned above the bottom navbar */
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
}

.photo-attribution p {
  margin: 0;
  color: white;
}

.photo-attribution a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.photo-attribution a:hover {
  border-bottom: 1px solid white;
}

.scale-after-slide {
  transition: transform 0.3s ease-out;
  transform: scale(0.75);
}

.slide-in.scale-after-slide {
  transform: scale(1);
}